import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../utils";
import { Button } from "reactstrap";
import BlogBtn from "./BlogBtn";

const BlogCard = ({ photo, createdAt, title, slug, id }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);

    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleResize);

    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);

  return (
    <div
      className="shadow-sm"
      style={{
        width: isMobile ? "350px" : "500px",
        backgroundColor: "white",
        padding: 0,
        borderRadius: "41px",
        marginTop: "100px",
      }}
    >
      <img
        style={{
          width: "100%",
          borderRadius: "41px",
          height: "300px",
        }}
        src={photo}
      />
      <div
        style={{
          padding: "40px",
        }}
      >
        <p
          style={{
            color: "#767676",
            fontWeight: "700",
            fontFamily: "Mulish, sans-serif",
            fontStyle: "normal",
            fontSize: "14px",
          }}
        >
          {formatDate(createdAt)}
        </p>
        <h2
          style={{
            fontWeight: "600",
            fontFamily: "Poppins, sans-serif",
            fontStyle: "normal",
            fontSize: isMobile ? "16px" : "24px",
            margin: "15px 0",
          }}
        >
          {title}
        </h2>
        <BlogBtn id={id} slug={slug} />
      </div>
    </div>
  );
};

export default BlogCard;
