import React from "react";
import MarkdownIt from "markdown-it";

// Initialize MarkdownIt
const md = new MarkdownIt();

const MarkdownRenderer = ({ content }) => {
  // Convert Markdown content to HTML
  const htmlContent = md.render(content);

  return (
    <>
      <div
        className="markdown-content"
        dangerouslySetInnerHTML={{ __html: htmlContent }} // Render HTML safely
      />
      <style jsx>{`
        @media (max-width: 576px) {
          .markdown-content img {
            width: 100%;
          }
        }
      `}</style>
    </>
  );
};

export default MarkdownRenderer;
