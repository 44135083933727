import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

const BlogBtn = ({ id, slug }) => {
  return (
    <Link
      // color="primary"
      outline
      className="mt-auto align-self-start"
      to={`/blog/${slug}/${id}`}
    >
      <Button
        color="primary"
        outline
        // className="mt-auto align-self-start"
        style={{
          borderRadius: "50px",
          background: "#FF7223",
          color: "white",
          borderColor: "#FF7223",
          fontFamily: "Mulish, sans-serif",
        }}
      >
        Read More
      </Button>
    </Link>
  );
};

export default BlogBtn;
