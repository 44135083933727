import axios from "axios";

export const fetchAllPost = async (page) => {
  let url = `/blogs?status=published&page=${page}&per_page=5`;

  const response = await axios.get(url).catch((e) => ({ error: e }));

  if (response.error) {
    const err = response?.error?.response;
    const msg = err?.data?.message || err?.status;
    throw new Error(msg);
  }

  return response?.data;
};

export const fetchPost = async (id) => {
  let url = `/blogs/${id}`;

  const response = await axios.get(url).catch((e) => ({ error: e }));

  if (response.error) {
    const err = response?.error?.response;
    const msg = err?.data?.message || err?.status;
    throw new Error(msg);
  }

  return response?.data;
};
